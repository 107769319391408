const PieChart = {
    options: {
        chart: {
            height: 365,
            type: 'pie',
        },
        dataLabels: {
            dropShadow: {
                enabled: false,
                top: 1,
                left: 1,
                blur: 1,
                opacity: 0.45
            }
        },
        colors: ['#fb5597', '#f59c1a', '#348fe2', '#00acac', '#8753de', '#32a852', '#a84e32', '#a89632', '#3e32a8', '#a032a8', '#3294a8', '#f2edf2'],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        title: {
            text: 'A pie chart showing varied Expiry bidbond amount figures in a year'
        }
    },
    series: [44, 55, 13, 43, 22, 30, 50, 100, 34, 60, 32, 10]
};

export default PieChart;
