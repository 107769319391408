<template>
  <div>
    <h4 class="page-header"> Bid Bond Expiry</h4>
    <div class="panel panel-inverse">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-5">
            <div
                class="form-group"
            >
              <label>Start Date</label>
              <datepicker
                  v-model="start"
                  format="yyyy-MM-dd"
                  input-class="form-control bg-white"
                  required
              />
            </div>
          </div>
          <div class="col-md-5">
            <div class="form-group">
              <label>End Date</label>
              <datepicker
                  v-model="end"
                  format="yyyy-MM-dd"
                  input-class="form-control bg-white"
                  required
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-7">
            <vue-good-table
                :columns="columns"
                :lineNumbers="true"
                :pagination-options="{ enabled: true }"
                :rows="bid_bonds"
                :search-options="{ enabled: true}">
              <div slot="table-actions">
                <download-excel
                    :data="reports"
                    class="btn btn-primary pull-right export-btn"
                    name="bidbonds.xls"
                    title="Bidbonds"
                >
                  <i class="fa fa-file-excel"></i> Export Excel
                </download-excel>
              </div>
            </vue-good-table>
          </div>
          <div v-if="reports.length > 0" class="col-md-5">
            <panel bodyClass="p-0" title="Pie Chart">
              <apexchart :options="pieChart.options" :series="pieChart.series" type="pie"></apexchart>
            </panel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from "./PieChart";
import helper from "@/mixins/helper";
import {mapGetters} from "vuex";
import moment from "moment/moment";

export default {
  mixins: [helper],
  data() {
    return {
      columns: [
        {
          label: 'DATE',
          field: 'date'
        },
        {
          label: 'Bid Bonds Expired Amount',
          field: 'amount',
          formatFn: this.formatFn
        },
      ],
      start: moment().startOf('year').toDate(),
      end: moment().toDate(),
      pieChart: PieChart
    };
  },
  created() {
    this.$store.dispatch('fetchExBidbonds', {from: this.start, to: this.end});
  },
  watch: {
    chart_data() {
      if (this.chart_data[1].length > 0)
        this.pieChart.options.colors = this.chart_data[1]
      if (this.chart_data[2].length > 0)
        this.pieChart.options.labels = this.chart_data[2]
      if (this.chart_data[0].length)
        this.pieChart.series = this.chart_data[0]
    },
    period() {
      if (this.start && this.end) {
        this.$store.dispatch('setExBidbondsReport', {from: this.start, to: this.end});
      }
    },
  },
  computed: {
    ...mapGetters({
      bid_bonds: 'getBidExpirybonds',
      reports: 'getBidbondsFormatted',
      chart_data: 'getBidChartData',
    }),
    period() {
      return [this.start, this.end].join();
    },
  },
}
</script>